.input-icon {
  position: absolute;
  /* left: 100px; */
  color: black;
  top: calc(45% - 0.2em);
  right: 520px;
  cursor: text;
  /* Keep icon in center of input, regardless of the input height */
}

.before-icon {
  position: absolute;
  /* left: 100px; */
  color: black;
  top: calc(40% - 0.1em);
  right: 20px;
  cursor: pointer;
  /* Keep icon in center of input, regardless of the input height */
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* top: calc(50% - 0.5em); */
}

.form {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}

.input-wrapper .upload {
  position: relative;
  text-align: right;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}

.main_ui {
  height: 80%;
  left: 0;
  /* margin-top: 20%;
    position: absolute; */
  top: 20%;
  width: 100%;
  flex-grow: 1;
  position: relative;
}

.toolBox {
  font-size: 16px !important;
}

.toolBox_warn {
  font-size: 16px !important;
  background-color: rgb(240, 179, 12) !important;
  color: black !important;
}
