.form__group {
  position: relative;
  padding: 3px 0 0;
  margin-top: 10px;
  width: 33%;
  cursor: text;
}

.form__field {
  font-family: 'Noto Sans', sans-serif;
  width: 90%;
  border: 0;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: #fff;
  cursor: text;
  margin-right: 10px;
  margin-left: 0px;
  padding: 18px 0 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 1.3rem;
  cursor: text;
  top: 30px;
}

.form__label {
  position: absolute;
  top: 0;
  line-height: 10px;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #9b9b9b;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #11998e, #38ef7d);
  border-image-slice: 1;
}

.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #11998e;
  font-weight: 700;
}

.toolBox {
  font-size: 16px !important;
}
