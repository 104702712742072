.tooltip {
  width: 400px !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: black !important;
  box-shadow: 1px 1px 8px 2px #000075 !important;
  background-color: #2196f3 !important;
}

.info-imp {
  color: rgb(228, 15, 15);
  cursor: pointer;
}

.info {
  color: rgb(255, 136, 0);
  cursor: pointer;
}
