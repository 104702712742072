.body {
  box-sizing: border-box;
}

.dndflow {
  flex-direction: column;
  background-image: url('../img/P.png');
  background-repeat: no-repeat;
  background-position: 0% 115%;
  display: flex;
  height: 1032px;
}

.dndflow aside {
  position: relative;
  padding: 15px 20px;
  font-size: 15px;
  height: 80rem;
  background: linear-gradient(180deg, #011f31 30%, #000000 90%);
  /* background: #000000; */
}

.dndflow aside > * {
  margin-bottom: 10px;
  cursor: grab;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }
  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}

.react-flow__controls {
  position: fixed !important;
  z-index: 5 !important;
  bottom: 10px !important;
  left: 10px !important;
}

/* customising connection line style*/

.react-flow__edge-path {
  stroke: #242424 !important;
  stroke-width: 4px !important;
  cursor: pointer;
}

#react-flow__arrow {
  color: #000000 !important;
}

/* customising handle style*/

.react-flow {
  height: 100rem !important;
  overflow: scroll;
  position: fixed;
  width: 100%;
}

.react-flow__handle {
  background: transparent !important;
  border-radius: 0 !important;
  border: 0 !important;
  height: 100% !important;
  right: 0 !important;
  width: 20% !important;
}

.react-flow_handle_multiple {
  background: rgba(255, 255, 255, 0) !important;
  border-radius: 0 !important;
  border: 0 !important;
  height: 21% !important;
  padding-top: 31px !important;
  right: 0 !important;
  width: 20% !important;
}

.react-flow__minimap {
  position: fixed !important;
  z-index: 5 !important;
  bottom: 10px !important;
  background-color: #011f31 !important;
  right: 16rem !important;
}

.react-flow__edge-textbg {
  fill: #d4d4d4;
  cursor: pointer;
}

.react-flow__edge.animated path {
  stroke-dasharray: 5;
  stroke-width: 5;
  -webkit-animation: dashdraw 0.5s linear infinite;
  animation: dashdraw 0.5s linear infinite;
}

.Zoom_btn {
  color: rgb(255, 0, 0);
}

.react-flow__zoompane {
  max-height: 100%;
  max-width: 100%;
  overflow: initial;
}

.tour {
  color: rgb(0, 60, 255);
}
