.cont {
  box-sizing: border-box;
  background-image: url('../img/intro-bg.jpg');
  background-color: rgba(0, 0, 0, 0.767);
  background-size: cover;
  background-repeat: no-repeat;
  /* background: linear-gradient(180deg, #011f31e0 30%, #000000 90%); */
  /* background: linear-gradient(180deg, #8f8f8f71 30%, #011f31e0 90%); */
  /* background-size: 5rem; */
  -webkit-filter: blur(0.125rem);
  -moz-filter: blur(0.125rem);
  -o-filter: blur(0.125rem);
  -ms-filter: blur(0.125rem);
  filter: blur(0.125rem);
  position: fixed;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  margin-bottom: 18.75rem;
  /* Center and scale the image nicely */
}

/* Position text in the middle of the page/image */

.bg-text {
  /* Fallback color */
  background-color: rgb(0 0 0 / 36%);
  /* Black w/opacity/see-through */
  color: rgb(255, 255, 255);
  font-weight: bold;
  border: 0.188rem solid #f1f1f1;
  position: absolute;
  top: 50%;
  text-align: center;
  align-items: center;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: max-content;
  height: fit-content;
  padding: 2.5rem;
  /* height: 100%; */
  box-sizing: border-box;
  text-align: center;
}

.bg-text label {
  color: #eeeeee;
  font-family: 'lora', sans-serif !important;
  font-size: 350% !important;
  box-sizing: border-box;
  font-weight: 200 !important;
}

.nav_buttons {
  height: 5%;
  padding: 1.25rem;
  /* height: 100%; */
  box-sizing: border-box;
}

.nav_buttons .btn {
  background: transparent;
  border: 0.125rem solid #ffffff;
  box-sizing: border-box;
  font-size: 1.4rem;
  font-weight: 100;
  margin: 0rem;
  margin-top: 3.75rem;
  margin-left: 0.625rem;
  border-radius: 0;
  box-shadow: 0 0.188rem 0.188rem 0.125rem rgb(156, 156, 156, 0.3);
  color: #ffffff;
  height: 5.5rem !important;
  padding: 1.75rem;
}

.btn:hover {
  background-color: #fff;
  background: linear-gradient(45deg, #999da0, #46535a);
  color: #000000;
  cursor: pointer;
}

.fac_form_cont {
  background-color: rgba(0, 0, 0, 0);
  color: rgb(255, 255, 255);
  font-weight: bold;
  margin-top: 0rem;
  position: absolute;
  top: 40%;
  text-align: center;
  align-items: center;
  font-size: 'Inconsolata', monospace;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 50%;
  height: 35%;
  padding: 1.5rem;
  /* height: 100%; */
  box-sizing: border-box;
  text-align: center;
}

.Footer_con {
  color: rgb(0, 0, 0);
  /* top: 17%; */
  bottom: 1%;
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-between;
  /* align-content: flex-end; */
  position: fixed;
  background: rgba(246, 246, 246, 0.39);
  /* height: 2.5rem; */
  /* padding: 4px; */
  font-weight: 700 !important;
  font-size: 1.3rem !important;
  align-items: flex-end;
}

.Footer_left {
  bottom: 0.5rem;
  align-items: flex-end;
}

.Footer_right {
  bottom: 0.5rem;
  right: 1rem;
  padding-right: 0.5rem;
  align-items: flex-end;
}

.MuiTypography-colorTextSecondary {
  color: rgb(0 0 0 / 54%) !important;
}

.MuiTypography-body1 {
  font-size: 1.2rem !important;
  font-weight: 500 !important;
}

.loading {
  background-color: rgba(122, 0, 0, 0);
  color: rgb(0, 0, 0);
  font-weight: bold;
  border: 0;
  position: fixed;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
