.title {
  font-size: 1.2rem;
  text-align: initial !important;
  margin-top: 0%;
  color: #f3f3f3;
  list-style-type: decimal;
  font-weight: 200;
}

.container {
  transform: translateY(-100%);
}

.container p {
  color: red;
  text-align: center;
}

.upload-icon {
  /* width: 50px;
    height: 50px; */
  /* background: url(../images/upload.png) no-repeat center center; */
  background-size: 100%;
  text-align: center;
  margin: 0 auto;
  /* padding-top: 30px; */
}

.drop-message {
  text-align: center;
  color: #d7dde2;
  font-family: Arial;
  font-size: 20px;
  display: inline-grid;
}

.success {
  white-space: nowrap;
  display: inline-block;
  border-radius: 0.313rem;
  font-weight: 600;
  padding: 0.513rem 1.238rem;
  width: 9rem;
  height: 3rem;
  padding: 0.513rem 1.238rem;
  font-size: 1.2rem;
  color: white;
  cursor: pointer;
  background-image: linear-gradient(#4fac11, #12df23);
  border: 0.063rem solid #000000;
  color: rgb(0, 0, 0);
}

.download {
  white-space: nowrap;
  display: inline-block;
  border-radius: 0.313rem;
  padding: 0.7rem 1.238rem;
  font-size: 1rem;
  font-weight: 600;
  height: 3.5rem;
  color: white !important;
  cursor: pointer;
  padding-right: 1rem !important;
  margin-right: 1rem !important;
  background: transparent;
  border: 0.063rem solid #f94c0a;
  color: rgb(0, 0, 0);
}

.upload {
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 389px;
  max-width: 508px;
  height: 48px;
}

.drop_zone {
  align-items: center !important;
  min-width: 400px !important;
  max-width: 508px !important;
  display: flex;
  justify-content: space-around;
  cursor: copy;
  height: 4.7rem !important;
  border: dashed 2px #f94c0a !important;
  padding: 8px 16px 8px 8px !important;
}

.drop_zone hover {
  align-items: center !important;
  min-width: 400px !important;
  max-width: 508px !important;
  display: flex;
  justify-content: space-around;
  cursor: copy;
  height: 4.7rem !important;
  border: dashed 2px #f94c0a !important;
  padding: 8px 16px 8px 8px !important;
}

.hKdZLT > span {
  font-size: 20px !important;
  color: rgb(189, 188, 188) !important;
}

.hKdZLT .file-types {
  overflow: visible !important;
  white-space: nowrap;
  text-overflow: clip;
  max-width: 105px;
}

.uplLine {
  color: rgb(233, 233, 233) !important;
  cursor: pointer !important;
  text-decoration: none !important;
}

.btnctn {
  display: block;
  flex-flow: row nowrap;
  justify-content: center;
  padding-bottom: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
}
