.popup {
  position: relative;
  top: 0px;
  left: 0;
  width: 100%;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  /* justify-content: center;
  align-items: center; */
}

.popup_config {
  max-width: 70rem;
  margin: 20px auto;
  font-size: 1em;
  margin-top: 3rem;
  border: 2px solid rgba(0, 0, 0);
  padding: 60px;
  padding-top: 0px;
  padding-bottom: 0rem;
  background-color: #090a0ded;
  color: rgba(29, 29, 29, 0.993);
  box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
  border-radius: 3px;
  height: 60rem;
  /* overflow-y: scroll;*/
}

.popup_config .inner {
  border: 2px solid black;
  padding: 30px;
  width: 100%;
  background-color: rgba(32, 33, 39, 0.692);
  overflow-y: scroll;
  height: 53rem;
  line-height: 0rem;
}

.popup-inner {
  max-width: max-content;
  margin: 20px auto;
  font-size: 1em;
  margin-top: 3rem;
  border: 2px solid rgba(0, 0, 0);
  padding: 60px;
  padding-top: 0px;
  padding-bottom: 3rem;
  background-color: #090a0ded;
  color: rgba(29, 29, 29, 0.993);
  box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
  border-radius: 3px;
  height: max-content;
  /* overflow-y: scroll;*/
}

.popup-inner .inner {
  border: 2px solid black;
  padding: 30px;
  width: 100%;
  background-color: rgba(32, 33, 39, 0.692);
  height: max-content;
  line-height: 5rem;
}

.button_container {
  display: flex;
  /* align-items: flex-end;
  margin-bottom: 1.8rem; */
  justify-content: space-between;
}

.button_container .close-btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  bottom: 0.5rem;
  cursor: pointer;
  outline: inherit;
  position: relative;
  left: 3.5rem;
}

.button_container .close-btn .icon {
  color: red;
}

.popup-Fac {
  max-width: 40rem;
  margin: 20px auto;
  font-size: 1em;
  margin-top: 7rem;
  border: 2px solid rgba(0, 0, 0);
  padding: 60px;
  height: min-content;
  padding-top: 0px;
  padding-bottom: 4rem;
  background-color: #090a0ded;
  color: rgba(29, 29, 29, 0.993);
  box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
  border-radius: 3px;
}

.popup-Fac .inner {
  border: 2px solid black;
  padding: 30px;
  width: 100%;
  background-color: rgba(32, 33, 39, 0.692);
  height: min-content;
}

.Formtitle {
  color: whitesmoke;
  font-size: 24px;
  line-height: 0px;
  float: left;
  text-transform: capitalize;
}

.facilityTitle {
  color: whitesmoke;
  font-size: 22px;
  line-height: 0px;
}

.popup-upload {
  max-width: 48rem;
  margin: 20px auto;
  font-size: 1em;
  margin-top: 7rem;
  border: 2px solid rgba(0, 0, 0);
  padding: 60px;
  height: min-content;
  padding-top: 0px;
  padding-bottom: 4rem;
  background-color: #090a0ded;
  color: rgba(29, 29, 29, 0.993);
  box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
  border-radius: 3px;
}

.popup-upload .inner {
  border: 2px solid black;
  padding: 30px;
  width: 100%;
  background-color: rgba(32, 33, 39, 0.692);
  height: min-content;
}

.uploadTitle {
  color: whitesmoke;
  font-size: 24px;
  padding-top: 14px;
}
