* {
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans', sans-serif;
}

body {
  margin: 0;
  background-color: #d4d4d4;
}

*::-webkit-scrollbar {
  width: 1em;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(70, 70, 70);
  border-radius: 10px;
  outline: 1px solid rgb(0, 0, 0);
}
