.form_container {
  max-width: 75rem;
  background-color: rgba(31, 31, 31, 0.7);
  margin: 1.25rem auto;
  border: 0.125rem solid rgba(0, 0, 0);
  padding: 1.25rem;
  color: white;
  box-shadow: 0.125rem 0.125rem 0.313rem rgba(0, 0, 0, 0.3);
  border-radius: 0.188rem;
}

.form_container .row {
  display: table-row;
  width: 100%;
  height: 30%;
}

.form_container .column2 {
  /* float: left; */
  display: grid;
  width: 30%;
  /* padding: 0.625rem;
  height: 300px; Should be removed. Only for demonstration */
}

.form_container .column70 {
  /* float: left; */
  display: grid;
  width: 70%;
  /* padding: 0.625rem;
  height: 300px; Should be removed. Only for demonstration */
}

.form_container .column3 {
  /* float: left; */
  display: grid;
  width: 10%;
  /* padding: 0.625rem;
height: 300px; Should be removed. Only for demonstration */
}

.form_container .column {
  /* float: left; */
  display: grid;
  width: 35%;
  /* padding: 0.625rem;
    height: 300px; Should be removed. Only for demonstration */
}

.form_container2 .config {
  font-family: 'Noto Sans', sans-serif;
  width: 40% !important;
  border: 0;
  outline: 0;
  border-bottom: 0.125rem solid #9b9b9b;
  font-size: 1.3rem;
  color: #fff;
  text-align: center;
  margin-right: 0.625rem;
  display: inline-block;
  margin-left: 0rem;
  padding: 1.125rem 0.375rem 0;
  background: #1d1f24;
  transition: border-color 0.2s;
}

.form_container2 {
  max-width: 75rem;
  background-color: rgba(31, 31, 31, 0.7);
  margin: 1.25rem auto;
  border: 0.125rem solid rgba(0, 0, 0);
  padding: 1.25rem;
  line-height: 3em;
  color: white;
  box-shadow: 0.125rem 0.125rem 0.313rem rgba(0, 0, 0, 0.3);
  border-radius: 0.188rem;
}

.form_container2 .opt {
  font-family: 'Noto Sans', sans-serif;
  width: 60%;
  border: 0;
  border-bottom: 0.125rem solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: #fff;
  text-align: center;
  margin-right: 0.625rem;
  display: inline-block;
  margin-left: 0rem;
  padding: 1.125rem 0.375rem 0;
  background: #1d1f24;
  transition: border-color 0.2s;
}

.starttime {
  font-family: 'Noto Sans', sans-serif;
  width: 60%;
  border: 0;
  outline: 0;
  font-size: 1.3rem;
  color: #fff;
  text-align: center;
  margin-right: 0.625rem;
  display: inline-block;
  margin-left: 0rem;
  padding: 1.125rem 0.375rem 0;
  background: #1d1f24;
  transition: border-color 0.2s;
}

.con {
  font-family: 'Noto Sans', sans-serif;
  width: 30%;
  border: 0;
  border-bottom: 0.125rem solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: #fff;
  text-align: center;
  margin-right: 0.625rem;
  display: inline-block;
  margin-left: 0rem;
  padding: 1.125rem 0.375rem 0;
  background: #1d1f24;
  transition: border-color 0.2s;
}

.form_container p {
  display: inline;
  font-size: 0.9rem;
}

.form_container2 .column {
  /* float: left; */
  display: inline-block;
  width: 75%;
  /* padding: 0.625rem;
    height: 300px; Should be removed. Only for demonstration */
}

.opt_input {
  font-family: 'Noto Sans', sans-serif;
  width: 7.2rem;
  border: 0;
  border-bottom: 0.125rem solid #9b9b9b;
  outline: 0;
  font-size: 1.1rem;
  color: #fff;
  margin-right: 0.625rem;
  margin-left: 0rem;
  padding: 1.125rem 0.25rem 0;
  background: #1d1f24;
  transition: border-color 0.2s;
}
.opt_name {
  font-family: 'Noto Sans', sans-serif;
  width: 80%;
  border: 0;
  border-bottom: 0.125rem solid #9b9b9b;
  outline: 0;
  font-size: 1.1rem;
  color: #fff;
  margin-right: 0.625rem;
  margin-left: 0.5rem;
  padding: 1.125rem 0.25rem 0;
  background: #1d1f24;
  transition: border-color 0.2s;
}

.currency {
  font-family: 'Noto Sans', sans-serif;
  width: 50% !important;
  border: 0;
  border-bottom: 0.125rem solid #9b9b9b;
  outline: 0;
  font-size: 1.1rem;
  color: #fff;
  margin-right: 0.625rem;
  margin-left: 0rem;
  padding: 1.125rem 0.25rem 0;
  background: #1d1f24;
  transition: border-color 0.2s;
}

.resource {
  margin-left: 1.5rem !important;
}
.opt {
  font-family: 'Noto Sans', sans-serif;
  width: 70%;
  border: 0;
  border-bottom: 0.125rem solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: #fff;
  margin-right: 0.625rem;
  margin-left: 0rem;
  padding: 1.125rem 0.25rem 0;
  background: #1d1f24;
  transition: border-color 0.2s;
}

.opt[type='checkbox'] {
  position: relative;
  display: inline;
  width: 6.875rem;
  height: 1.125rem;
  cursor: pointer;
  margin-top: 1.25rem;
  border: 0.063rem solid #808080;
  content: '';
  background: #fff;
}

.opt_input[type='number']:disabled {
  font-family: 'Noto Sans', sans-serif;
  width: 34%;
  border: 0;
  background-color: lightgrey;
  border-bottom: 0.125rem solid #9b9b9b;
  outline: 0;
  text-align: center;
  font-size: 1.3rem;
  color: rgb(0, 0, 0);
  margin-right: 0.625rem;
  margin-left: 0rem;
  padding: 1.125rem 0 0;
  cursor: not-allowed;
  background: rgba(128, 128, 128, 0.383);
  transition: border-color 0.2s;
}

.formField {
  font-family: 'Noto Sans', sans-serif;
  width: 70%;
  border: 0;
  border-bottom: 0.125rem solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: #fff !important;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  padding: 1.125rem 0.25rem 0;
  background: #1d1f24;
  transition: border-color 0.2s;
}

label {
  color: white;
  width: 7.813rem !important;
  font-size: 1.12rem !important;
  line-height: 2rem;
}

input,
select,
option,
optgroup {
  font-family: 'Noto Sans', sans-serif;
  width: 70%;
  border: 0;
  border-bottom: 0.125rem solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  cursor: text;
  color: #fff !important;
  margin-right: 0.625rem;
  margin-left: 0rem;
  padding: 1.125rem 0.25rem 0;
  background: #1d1f24;
  transition: border-color 0.2s;
}

textarea {
  font-family: 'Noto Sans', sans-serif;
  width: 100%;
  height: 8rem;
  border: 0;
  border-bottom: 0.125rem solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: #fff !important;
  margin-right: 0.625rem;
  margin-left: 0rem;
  padding: 1.125rem 0.25rem 0;
  cursor: text;
  background: #1d1f24;
  transition: border-color 0.2s;
}

input:-webkit-autofill {
  -webkit-text-fill-color: rgb(228, 228, 228) !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

input:disabled {
  -webkit-text-fill-color: rgb(255, 255, 255);
  background: rgba(128, 128, 128, 0.383);
  transition: border-color 0.2s;
  cursor: not-allowed;
}

select:disabled {
  -webkit-text-fill-color: rgb(255, 255, 255);
  transition: background-color 5000s ease-in-out 0s;
  cursor: not-allowed;
}

.btn-ctn {
  display: block;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: 1.25rem;
  padding-top: 2rem;
  cursor: pointer;
}

.add_icon {
  background-image: linear-gradient(#39ff00, #2fb61a) !important;
  border: none !important;
  color: rgb(0, 0, 0) !important;
  cursor: pointer;
  padding: 1.25rem;
}

.remove_icon {
  background-image: linear-gradient(#ff0000, #b61a1a) !important;
  border: none !important;
  color: rgb(0, 0, 0) !important;
  cursor: pointer;
  padding: 1.25rem;
}

.buttons {
  display: inline;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: 0.938rem;
  margin-left: 1.25rem;
  cursor: pointer;
  background: transparent;
  height: 2.2rem;
  width: 2.8rem;
  font-size: 0.75rem;
  color: white;
  border: 0.125rem solid white;
  padding: 0.25rem;
  box-shadow: 0.125rem 0.125rem 0.313rem rgba(212, 182, 12, 0.719);
}

.buttons:hover {
  color: #000000;
  background-color: #505050;
}

.submit {
  white-space: nowrap;
  display: inline-block;
  border-radius: 0.375rem;
  padding: 0.313rem 0.938rem;
  font-weight: 700;
  font-size: 1rem;
  color: rgb(255, 255, 255);
  cursor: pointer;
  background-image: linear-gradient(#4f93ce, #285f8f);
}

.reset {
  white-space: nowrap;
  display: inline-block;
  border-radius: 0.313rem;
  padding: 0.313rem 0.938rem;
  font-size: 1rem;
  color: white;
  cursor: pointer;
  background-image: linear-gradient(#ffffff, #d5d5d5);
  border: 0.063rem solid #d5d5d5;
  color: #555;
}

.text-danger {
  color: red;
  font-size: 0.938rem;
  display: flex;
}

option {
  font-size: 1.125rem;
}

.dropdown {
  font-family: 'Noto Sans', sans-serif;
  width: -webkit-fill-available;
  border: 0;
  border-bottom: 0.125rem solid #9b9b9b;
  outline: 0;
  font-size: 1.125rem;
  color: #fff !important;
  margin-right: 0.625rem;
  text-align: center;
  margin-left: 0rem;
  padding: 1.125rem 0.25rem 0;
  background: #1d1f24;
  transition: border-color 0.2s;
}

.array {
  font-family: 'Noto Sans', sans-serif;
  width: 5%;
  border: 0;
  border-bottom: 0.125rem solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: #fff;
  margin-right: 0.625rem;
  margin-left: 0rem;
  padding: 1.125rem 0.375rem 0;
  background: #1d1f24;
  transition: border-color 0.2s;
}

.desc {
  font-family: 'Noto Sans', sans-serif;
  width: 90%;
  height: 6rem;
  border: 0;
  border-bottom: 0.125rem solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: #fff !important;
  margin-right: 0.625rem;
  margin-left: 0rem;
  padding: 1.125rem 0.25rem 0;
  cursor: text;
  background: #1d1f24;
  transition: border-color 0.2s;
}

.icon {
  padding: 0.625rem;
  padding-right: 0rem;
  padding-left: 1rem;
  color: white;
  text-align: center;
}

.rdtPicker {
  display: none;
  position: absolute;
  min-width: 15.625rem;
  padding: 0.25rem;
  margin-top: 0.063rem;
  z-index: 99999 !important;
  background: #1f1f20 !important;
  box-shadow: 0 0.063rem 0.188rem rgba(0, 0, 0, 0.1);
  border: 0.063rem solid #f9f9f9;
}

.rdtPicker:hover {
  background: #070606 !important;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover,
.rdtPicker thead tr:first-of-type th:hover,
.rdtCounter .rdtBtn:hover,
.rdtPicker button:hover,
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #747474 !important;
  color: white !important;
  cursor: pointer;
}

.rdtCounter .rdtBtn {
  height: 35%;
  line-height: 60px !important;
  cursor: pointer;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.color-picker {
  position: relative;
  display: flex;
  top: 20px;
  justify-content: center;
}
.ColorPicker-makeStyles-root-3 {
  background-image: none !important;
  background-color: none !important;
  padding: 0 !important;
  border-color: rgb(118, 118, 118) !important;
  border-width: 0px !important;
  width: 24px !important;
  min-width: 24px !important;
  height: 24px !important;
}

.ColorPicker-MuiInputBase-input {
  color: currentColor !important;
}

.lab {
  align-items: flex-start !important;
}

.column30 {
  width: 30%;
  margin-left: 0px !important;
}

.column70 {
  width: 70%;
  padding-left: 0;
  margin-left: 0px !important;
}

.column70 .opt {
  font-family: 'Noto Sans', sans-serif;
  width: -webkit-fill-available;
  border: 0;
  border-bottom: 0.125rem solid #9b9b9b;
  outline: 0;
  cursor: text;
  font-size: 1.3rem;
  color: #fff;
  margin-right: 0.625rem;
  margin-left: 0rem;
  padding: 1.125rem 0.25rem 0;
  background: #1d1f24;
  transition: border-color 0.2s;
}

.column70 .curr {
  font-family: 'Noto Sans', sans-serif;
  width: 96%;
  border: 0;
  border-bottom: 0.125rem solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: #fff;
  margin-right: 0.625rem;
  cursor: text;
  margin-left: 0rem;
  padding: 1.125rem 0.25rem 0;
  background: #1d1f24;
  transition: border-color 0.2s;
}

.dollar {
  /* padding-left: 1rem; */
  color: white;
  text-align: center;
}
