.FacilitySelect {
  /* Fallback color */
  background-color: rgb(0 0 0 / 36%);
  color: rgb(255, 255, 255);
  font-weight: bold;
  border: 3px solid #f1f1f1;
  position: absolute;
  top: 50%;
  text-align: center;
  align-items: center;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 50%;
  height: fit-content;
  padding: 24px;
  /* height: 100%; */
  box-sizing: border-box;
  text-align: center;
}

.FacilitySelect .close-btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: absolute;
  top: 0px;
  text-align: end;
  align-items: flex-end;
  right: 0px;
}

.FacilitySelect .close-btn .icon {
  color: red;
}

.FacilitySelect h3 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  box-sizing: border-box;
  line-height: 20px;
}

.selectFac {
  font-family: 'Noto Sans', sans-serif;
  width: -webkit-fill-available;
  border: 0;
  border-bottom: 2px solid #afacac;
  outline: 0;
  height: 4rem;
  text-align: center !important;
  text-align-last: center !important;
  font-size: 1.3rem;
  color: rgb(255, 255, 255) !important;
  margin-right: 5rem;
  margin-left: 0px;
  padding: 16px 7px 16px 9px;
  background: rgb(0 0 0 / 36%);
  transition: border-color 0.2s;
}
/*
.selectFac {
  font-family: 'Noto Sans', sans-serif;
  width: -webkit-fill-available;
  border: 0;
  outline: 0;
  height: 4rem;
  text-align: center !important;
  text-align-last: center !important;
  font-size: 1.3rem;
  color: rgb(255, 255, 255) !important;
  margin-right: 5rem;
  margin-left: 0px;
  padding: 16px 7px 16px 9px;
  background: rgb(0 0 0 / 0%);
  transition: border-color 0.2s;
} */

.sortfac {
  font-family: 'Noto Sans', sans-serif;
  width: -webkit-fill-available;
  border: 0;
  border-bottom: 2px solid #afacac;
  outline: 0;
  height: 4rem;
  text-align: center !important;
  text-align-last: center !important;
  font-size: 1.3rem;
  color: rgb(255, 255, 255) !important;
  /* margin-right: 5rem; */
  margin-left: 0px;
  padding: 16px 7px 16px 9px;
  background: rgb(0 0 0 / 36%);
  transition: border-color 0.2s;
}

.box_title {
  font-size: 1.8rem;
  color: rgb(238, 236, 236);
}

.btn_ctn {
  display: block;
  flex-flow: row nowrap;
  justify-content: center;

  padding: 1.5rem 0rem 1.5rem 2rem;
  cursor: pointer;
}

.del {
  white-space: nowrap;
  display: inline-block;
  border-radius: 0.375rem;
  padding: 0.313rem 0.938rem;
  margin: 5px;
  font-size: 1rem;
  color: rgb(255, 255, 255);
  cursor: pointer;
  background-image: linear-gradient(#fd0202, #921c07);
}

.sel {
  white-space: nowrap;
  display: inline-block;
  border-radius: 0.375rem;
  width: 6rem;
  padding: 0.313rem 0.938rem;
  margin: 5px;
  font-size: 1rem;
  color: rgb(255, 255, 255);
  cursor: pointer;
  background-image: linear-gradient(#0277fd, #071092);
}

.btn_none {
  background: none;
  cursor: pointer;
  border: None;
  padding: 10px 13px;
  display: contents !important;
  position: absolute !important;
  bottom: 0;
}
