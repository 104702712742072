.table {
  height: 300 !important;
  width: 100%;
  color: white;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderDraggableContainer {
  width: max-content !important;
  display: flex !important;
}

.MuiIconButton-root {
  flex: 0 0 auto;
  color: rgb(255 252 252 / 54%) !important;
  padding: 12px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
}

.MuiDataGrid-root {
  flex: 1;
  color: rgba(252, 252, 252, 0.87) !important;
  border: 1px solid rgba(224, 224, 224, 1);
  height: 100%;
  display: flex;
  outline: none;
  position: relative;
  font-size: 0.875rem;
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 4px;
  flex-direction: column;
  letter-spacing: 0.01071em;
}

.MuiGridFilterForm-root {
  display: flex;
  padding: 8px;
  background-color: #1f1f20 !important;
  color: white !important;
}

.MuiGridPanel-paper {
  display: flex;
  min-width: 300px;
  max-height: 450px;
  color: rgb(255, 254, 254) !important;
  background-color: #1f1f20 !important;
}

.MuiFormLabel-root {
  color: rgb(247 247 247) !important;
  padding: 0;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}
.MuiDataGrid-iconButtonContainer {
  width: 5% !important;
}

.MuiTypography-body2 {
  font-size: 0.875rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  color: rgb(255, 255, 255) !important;
  letter-spacing: 0.01071em;
}
