.react-flow__node {
  padding: 30px !important;
  border-radius: 3px !important;
  width: 13rem !important;
  display: flex !important;
  box-shadow: inset 0px 0px 7px #19262e !important;
  font-weight: bolder !important;
  /* background-color: rgb(219, 219, 219); */
  text-align: inherit !important;
  line-height: 22px !important;
  align-items: center !important;
}

.react-flow__node span {
  display: inline;
  font-size: 1.2rem;
  line-height: 16px !important;
}

.react-flow__node-default {
  padding: 30px;
  border-radius: 3px;
  width: 13rem;
  box-shadow: inset 0px 0px 7px #19262e;
  font-weight: bolder;
  background-color: rgb(219, 219, 219);
  /* background-repeat: no-repeat; */
  font-size: x-large;
  text-align: center;
}
