.Assumptions {
  /* Fallback color */
  background-color: rgb(225 229 240 / 86%);
  color: rgb(0, 0, 0);
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 1.5rem;
  box-shadow: 0.125rem 0.938rem 0.375rem rgb(0 0 0 / 46%);
  border: 0.188rem solid #f1f1f1;
  position: absolute;
  top: 0;
  text-align: center;
  align-items: center;
  left: 10%;
  /* transform: translate(-50%, -50%); */
  z-index: 2;
  width: 80%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1.5rem;
  box-sizing: border-box;
  text-align: center;
}

.Assumptions .close-btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0rem;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: absolute;
  top: 0rem;
  text-align: end;
  align-items: flex-end;
  right: 0rem;
}

.Assumptions .close-btn .icon {
  color: red;
}

.Assumptions h3 {
  color: rgb(0, 0, 0);
  font-size: 1.25rem;
  box-sizing: border-box;
  line-height: 1.25rem;
}

.Assumptions ul {
  list-style-type: circle !important;
  font-size: 1.7rem;
  /* list-style-position: outside !important; */
}

.Assumptions li {
  text-align: left;
  list-style: none;
  display: list-item;
  margin-left: 1.875rem;
  margin: 1.25rem;
  /* list-style-position: outside !important; */
}

.Assumptions li:before {
  content: '';
  border: 0.313rem #000 solid !important;
  border-radius: 3.125rem;
  margin-top: 0.313rem;
  margin-left: -1.25rem;
  position: absolute;
}
