.Navbar {
  width: auto;
  height: auto;
  padding: 0.5% 2%;
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
  border-bottom: 3px solid #f94d0b;
  background: linear-gradient(203deg, #011f31 30%, #000000 90%);
  background-color: #000000;
  color: rgb(250, 249, 249);
}

.Navbar Link {
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}

.Navbar ul {
  list-style: none;
  margin: 0;
  padding-top: 15px !important;
  padding: 0;
}

.label {
  color: #e0e0e0;
  font-family: 'SF Pro Display,SF Pro Icons,Helvetica Neue,Helvetica,Arial', sans-serif cursive;
  height: 10%;
  font-size: 60px;
  /* padding-bottom: 3rem; */
  text-decoration: none;
  /* font-size: 10vw; */
  text-shadow: 4px 4px 0px #365365;
  mix-blend-mode: lighten;
  animation: fadeIn 2.5s linear forwards;
  /* margin-left: 0.5em; */
  /* border-style: double;
    background: linear-gradient(130deg, #353142 0%, #4a4f5c 100%); */
  font-weight: lighter;
  text-align: center;
  /* text-shadow: 0 2px 2px rgb(56, 53, 63); */
}

.toolBox {
  font-size: 13px !important;
}
